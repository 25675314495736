<template>
  <div>
    <b-list-group>
      <b-list-group-item
        v-for="(item, index) in content"
        :key="item.signum.id + signum(item.signum.id)"
      >
        <b-row>
          {{ $t("components.signumInscriptions.certainty") }}
          <b-col>
            <b-form-checkbox
              :id="'form-element-canonical-' + index"
              v-model="item.canonical"
              :name="'form-element-canonical-' + index"
              switch
              @change="toggleCanonical(index)"
            >
              {{
                item.canonical
                  ? $t("components.signumInscriptions.canonical")
                  : $t("components.signumInscriptions.noncanonical")
              }}
            </b-form-checkbox>
          </b-col>
          <b-col sm="auto">
            {{ names[item.signum.id] }}
          </b-col>
          <b-col sm="auto">
            <b-button
              v-t="'components.signumInscriptions.deletesignum'"
              @click="deletesignum(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <base-auto-complete
      :graph-query="completions"
      :placeholder="$t('components.signumInscriptions.addsignum')"
      :map="nameMap"
      @select="addsignum"
    >
      <!-- What to show when we have no results -->
      <template v-slot:noResult>
        <span>{{ $t("components.baseList.noResult") }}</span>
      </template>
      <!-- Template for each element in the auto complete -->
      <template v-slot:option="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
      <!-- Template for a selected element in the auto complete -->
      <template v-slot:singleLabel="{ option: { name } }">
        <span>{{ name }}</span>
      </template>
    </base-auto-complete>
  </div>
</template>

<script>
import gql from "graphql-tag";

export default {
  name: "SignumInscriptionComponent",
  components: {
    BaseAutoComplete: () => import("@/components/BaseAutoComplete")
  },
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value,
      names: {},
      completions: gql`
        query findSigna($search: String!) {
          completions: findSigna(signum: $search, limit: 20) {
            id
            signum1 {
              signum1
            }
            signum2
          }
        }
      `
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    toggleCanonical(target) {
      this.content.forEach((signum, index) => {
        if (index !== target) {
          signum.canonical = false;
        }
      });
      this.changed();
    },
    changed() {
      this.$emit("change");
    },
    addsignum(element) {
      this.content.push({
        canonical: this.content.length === 0,
        signum: {
          id: element.id
        }
      });
      this.$emit("change");
    },
    deletesignum(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    nameMap(element) {
      return {
        name: element.signum1.signum1 + " " + element.signum2,
        ...element
      };
    },
    signum(id) {
      if (this.names[id] != undefined) return;
      this.$apollo
        .query({
          query: gql`
            query signum($id: ID) {
              signum(id: $id) {
                id
                signum1 {
                  signum1
                }
                signum2
              }
            }
          `,
          variables: { id: id }
        })
        .then(result => {
          this.$set(
            this.names,
            result.data.signum.id,
            result.data.signum.signum1.signum1 +
              " " +
              result.data.signum.signum2
          );
        });
    }
  }
};
</script>
